<template>
  <div class="pdb-news-sources-date-filters">
    <a-date-picker
      @change="setDatesFilters()"
      placeholder="Start Date"
      class="start-date"
      v-model:value="newsContentStartDate"
      :disabledDate="disableNewsContentStartDate"
    />
    <a-date-picker
      @change="setDatesFilters()"
      placeholder="End Date"
      class="end-date"
      v-model:value="newsContentEndDate"
      :disabledDate="disableNewsContentEndDate"
    />
  </div>
</template>

<script>
export default {
  mounted() {
    this.emitter.on("clearNewsFilters", () => {
      this.newsContentStartDate = null,
      this.newsContentEndDate = null,
      this.$store.commit("discover/SET_START_DATE", null);
      this.$store.commit("discover/SET_END_DATE", null);
    });
    const startDate = this.$store.getters["discover/getStartDate"];
    const endDate = this.$store.getters["discover/getEndDate"];
    startDate ? this.newsContentStartDate = startDate : null;
    endDate ? this.newsContentEndDate = endDate : null;
  },
  data() {
    return {
      newsContentStartDate: null,
      newsContentEndDate: null,
    };
  },
  methods: {
    disableNewsContentStartDate(date) {
      if (!this.newsContentEndDate || !this.newsContentEndDate.startOf) {
        return false;
      }
      return date > this.newsContentEndDate.startOf("day");
    },
    disableNewsContentEndDate(date) {
      if (!this.newsContentStartDate || !this.newsContentStartDate.startOf) {
        return false;
      }
      return date < this.newsContentStartDate.startOf("day");
    },
    setDateFormat(date) {
      date =
        typeof date == "object" && (date !== "") & (date !== null)
          ? date._d.getFullYear() +
            `-${(date._d.getMonth() + 1).toString().length === 1 ? "0" : ""}` +
            (date._d.getMonth() + 1) +
            `-${date._d.getDate().toString().length === 1 ? "0" : ""}` +
            date._d.getDate()
          : date;
      return date;
    },
    setDatesFilters() {
      const startDate = this.setDateFormat(this.newsContentStartDate);
      const endDate = this.setDateFormat(this.newsContentEndDate);

      this.$store.commit("discover/SET_START_DATE", startDate);
      this.$store.commit("discover/SET_END_DATE", endDate);
    },
  },
};
</script>

<style lang="scss">
.pdb-news-sources-date-filters {
  .ant-calendar-picker {
    width: 100%;
    margin-bottom: 1.5rem;
    .ant-input {
      height: 4rem;
      font-size: 1.4rem;
      border: 1px solid #c5c6cc;
      font-family: $font-primary;
    }
    .anticon {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
