<template>
  <input class="" :placeholder="placeholder" />
</template>

<script>
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import "@yaireo/tagify/dist/tagify.css";

export default {
  name: "App",
  props: {
    placeholder: String,
    isSubmitted: Boolean,
    nullify: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      suggestions: [],
      selectedKeywords: [],
    };
  },
  watch: {
    isSubmitted(val) {
      this.ifSubmitted = val;
      if (val) {
        this.tagify.removeAllTags();
        this.selectedKeywords=[]
      }
    },
    nullify(val) { 
      if (val) { 
        this.tagify.removeAllTags();
        this.selectedKeywords = []
      }
    }
  },
  mounted() {
    // simulate fetchign async data and updating the sugegstions list
    // do not create a new Array, but use the SAME ONE
      this.tagify = new Tagify(this.$el);
    this.tagify.on("add", (e) => {
      this.selectedKeywords = [];
      e.detail.tagify.value.map((el) => {
        this.selectedKeywords.push(el.value);
        this.$emit("fetchKeywords", this.selectedKeywords);
      });
    });

    this.tagify.on("remove", (e) => {
      this.selectedKeywords=[]
      this.selectedKeywords=e.detail.tagify.value
      console.log(e.detail.tagify.value)
      this.$emit("fetchKeywords", this.selectedKeywords);
    });
  },
};
</script>

<style lang="scss">

</style>
