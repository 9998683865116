<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="50rem"
    class="send-message-modal"
  >
    <div class="send-message-modal-container">
      <h1 class="title">Send us a message about your questions</h1>
      <form @submit.prevent="submitMessage">
        <textarea
          class="textarea"
          v-model="message"
          required
          placeholder="Give us little more details about what went wrong! (Optional)"
        ></textarea>
        <p v-if="error" class="error">{{ error }}</p>
        <a-button
          @click="submitMessage()"
          class="primary-btn"
          :loading="loading"
        >
          Send Message
        </a-button>
      </form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      message: "",
      error: "",
      loading: false,
    };
  },
  emits: ["close-modal"],
  methods: {
    closeModal(msg) {
      this.$emit("close-modal", msg);
    },
    async submitMessage() {
      try {
        if (this.message === "") {
          this.error = "This field is required";
          return false;
        }
        this.loading = true;
        this.error = "";
        const payload = {
          question: this.message,
        };

        const response = await this.$store.dispatch(
          "profile/sendMessage",
          payload
        );
        if (response.status === 201) {
          this.closeModal('success');
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.send-message-modal {
  top: 15rem;
  .ant-modal-content {
    .ant-modal-close {
      margin: 3.1rem 2.7rem 0 0;
      .ant-modal-close-x {
        padding: 0;
        width: fit-content;
        height: fit-content;
        line-height: normal;
        .anticon {
          color: $color-black;
        }
      }
    }
    .ant-modal-body {
      padding: 0;
      .send-message-modal-container {
        padding: 2.8rem 2.7rem;
        .title {
          font-family: $font-primary-bold;
          font-size: 2rem;
          line-height: 2.7rem;
          margin-bottom: 3.8rem;
          text-align: left;
        }
        form {
          .textarea {
            width: 100%;
            border: 1px solid $color-dark-grey-5;
            color: $color-black;
            padding: 1.2rem 1.4rem;
            outline: none;
            font-family: $font-primary;
            font-size: 1.6rem;
            line-height: 2.2rem;
            margin: 0;
            height: 12rem;
            resize: none;
            &::placeholder {
              opacity: 0.8;
            }
          }
          .error {
            color: red;
            font-size: 1.4rem;
            font-family: $font-primary;
            margin-bottom: 0;
          }
          .primary-btn {
            color: $color-white;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            border: none;
            outline: none;
            background-color: $color-primary;
            width: 100%;
            height: 4.6rem;
            border-radius: 5px !important;
            cursor: pointer;
            margin-top: 2.4rem;
          }
        }
      }
    }
  }
}
</style>
