<template>
  <div @click="selectPackage(packageName)" :class="{ 'month-package-card' : packageName === 'Month'}" class="package-card">
    <!-- <img src="@/assets/icons/payment-complete-icon.svg" alt="Payment complete icon"> -->
    <h2>1 {{ packageName }} Subscription</h2>
    <p class="red" v-if="packageName === 'Year'">$ {{ actualPrice }}</p>
    <h1>$ {{ discountedPrice }}</h1>
    <p>Only ${{ calculatePerWeekPrice(actualPrice, duration) }}/ Week</p>
    <h5>Billed {{ packageName }}ly | Cancel anytime</h5>
  </div>
</template>


<script>
export default {
  props: {
    packageName: String,
    actualPrice: String,
    discountedPrice: String,
    duration: Number
  },
  methods: {
    calculatePerWeekPrice(planPrice, duration_in_days) {
      return (planPrice / Math.floor(duration_in_days / 7)).toFixed(2);
    },
    selectPackage(val) {
      this.$emit('select-package', val);
    }
  }
}
</script>

<style lang="scss" scoped>
.package-card {
  max-width: 30.4rem;
  border: 2px solid #12121212;
  padding: 2.9rem 3.8rem 2.7rem;
  height: 22.7rem;
  display: block;
  margin: 0 auto;
  border-radius: 1rem;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #FAFBFE;
  cursor: pointer;
  img {
    position: absolute;
    right: -1.8rem;
    top: -1.5rem;
    width: 3.5rem;
  }
  h2 {
    font-family: $font-primary-medium;
    font-size: 1.4rem;
    color: $color-black;
    opacity: 1;
    line-height: 1.8rem;
    text-align: center;
    margin-bottom: 2.4rem;
  }
  p {
    font-family: $font-primary-medium;
    font-size: 1.4rem;
    color: $color-dark-grey-6;
    line-height: 1.8rem;
    text-align: center;
    opacity: 1;
    width: fit-content;
    display: block;
    margin: 0 auto;
  }
  .red {
    color: #FF0000A6;
    position: relative;
    &::after {
      width: 100%;
      position: absolute;
      content: '';
      height: 1px;
      background-color: #FF0000A6;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h1 {
    font-family: $font-primary-bold-2;
    font-size: 3rem;
    color: $color-primary;
    text-align: center;
    line-height: normal;
    margin-bottom: 1.6rem;
  }
  h5 {
    font-family: $font-primary;
    font-size: 1.2rem;
    color: $color-dark-grey-6;
    line-height: 1.6rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 2.4rem;
    opacity: 1;
  }
}
.month-package-card {
  h2 {
    margin-bottom: 4rem;
  }
  h1 {
    color: $color-black;
  }
  .red {
    display: none;
  }
}
</style>
