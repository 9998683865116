<template>
  <div class="more-article-section">
    <a-collapse
      v-model:activeKey="activeKey"
      :expand-icon-position="(expandIconPosition = 'right')"
      class="more-article-section__collapse"
    >
      <a-collapse-panel key="1" header="Related News">
        <a-row :gutter="[18, 18]">
          <a-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" 
            v-for="insight in insights"
            :key="insight.id"
          >
          <article-card
            :title="insight.title[0]"
            :author-name="insight.authors[0]"
            :url="insight.article_url.url"
            :sourceLogo="insight.logo[0]"
            :source="insight.article_url.source"
            :image="insight.image[0]"
            :text="insight.description[0]"
            :id="insight.id[0]"
            :date="insight.pub_date[0]"
            :ratings="insight.rating"
            :aggregatedRating="insight.aggregated_rating"
            :userReaction="insight.user_reaction"
            :totalArticleReactions="insight.article_reactions"
            :userReactionRepresentation="insight.users_reaction_representation"
          >
          </article-card>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import ArticleCard from '../BaseComponents/ArticleCard.vue';
export default{
  components: {
    ArticleCard,
  },
  computed: {
    insights() {
      return this.$store.getters["discover/getInsights"];
    },
  },
  data(){
    return{
      articles: [
        {
          id: "12312ssdas1",
          title: "Tesla is organized into two main divisions based on their different product categories. Therefore there are two segments of infrastructure too.",
          authorName: "Muhammad Aliyan",
          image: require("../../assets/images/car.jpg"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          article_url: {
            id:"ea72e3e4-b665-42eb-90f3-40f65cee3db5",
            source: "cnbc",
            url: "https://www.cnbc.com/2023/09/11/asia-stock-markets-today-live-updates.html"
          },
          pub_date: "10 Sep 2023"
        },
        {
          id: "1231eresdas1",
          title: "Elon Musk Chief Executive Officer of Tesla Motors",
          authorName: "Haseeb Awan",
          image: require("../../assets/images/Elon-Musk.jpg"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          article_url: {
            id:"ea72e3e4-b665-42eb-90f3-40f65cee3db5",
            source: "cnbc",
            url: "https://www.cnbc.com/2023/09/11/asia-stock-markets-today-live-updates.html"
          },
          pub_date: "01 Sep 2022"
        },
        
        {
          id: "12312s213sdas1",
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          article_url: {
            id:"ea72e3e4-b665-42eb-90f3-40f65cee3db5",
            source: "cnbc",
            url: "https://www.cnbc.com/2023/09/11/asia-stock-markets-today-live-updates.html"
          },
          pub_date: "20 Sep 2023"
        },
        {
          id: "123hthyssdas1",
          title: "Tesla surpassed a market value of $1 trillion on Monday, making it the fifth such firm to reach the milestone.",
          authorName: "Adam Abid",
          image: require("../../assets/images/card-image2.png"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          article_url: {
            id:"ea72e3e4-b665-42eb-90f3-40f65cee3db5",
            source: "cnbc",
            url: "https://www.cnbc.com/2023/09/11/asia-stock-markets-today-live-updates.html"
          },
          pub_date: "31 Sep 2023"
        },
        {
          id: "12312ssasdsadas1",
          title: "Tesla is organized into two main divisions based on their different product categories. Therefore there are two segments of infrastructure too.",
          authorName: "Muhammad Aliyan",
          image: require("../../assets/images/car.jpg"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          article_url: {
            id:"ea72e3e4-b665-42eb-90f3-40f65cee3db5",
            source: "cnbc",
            url: "https://www.cnbc.com/2023/09/11/asia-stock-markets-today-live-updates.html"
          },
          pub_date: "10 Sep 2023"
        },
        {
          id: "12312ssnjls1",
          title: "Tesla is organized into two main divisions based on their different product categories. Therefore there are two segments of infrastructure too.",
          authorName: "Muhammad Aliyan",
          image: require("../../assets/images/car.jpg"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
          article_url: {
            id:"ea72e3e4-b665-42eb-90f3-40f65cee3db5",
            source: "cnbc",
            url: "https://www.cnbc.com/2023/09/11/asia-stock-markets-today-live-updates.html"
          },
          pub_date: "10 Sep 2023"
        },
      ],
      activeKey: "1"
    }
  }
}
</script>


<style lang="scss">
.more-article-section {
  margin-top: 5rem;
  &__collapse {
    border: none;
    background-color: $color-white;
    border-top: 2px solid rgba(0, 0, 0, 0.368);
    .ant-collapse-item {
      border-radius: 0 !important;
      border: none;
      .ant-collapse-header {
        padding: 0 2rem !important;
        margin: 5rem 0 !important;
        background-color: transparent !important;
        border: none;
        border-radius: 0;
        width: fit-content;
        font-size: 2.4rem;
        font-family: $font-primary-bold;
        width: 700;
        line-height: 2.4rem;
        color: $color-black;
        .anticon {
          right: -0.6rem;
          font-size: 2rem;
        }
        &::before {
          position: absolute;
          content: "";
          height: 0.4rem;
          bottom: -1.5rem;
          left: 2rem;
          width: 6rem;
          background-color: $color-primary;
        }
      }
      .ant-collapse-content {
        border: none;
        .ant-collapse-content-box {
          padding: 0 2rem 5rem;
        }
      }
    }
  }
}
</style>
