<template>
  <div class=" add-new-topic-container">
    <div @click.stop="" class=" add-new-topic">
      <div class="add-new-topic__body">
        <div class="input-field">
          <p class="title">Topic Name</p>
          <div class="add-input">
            <div class="add-new-topic-select">
              <input type="text" placeholder="Type name"
                v-model="addTopic"
                @input="fetchAndFilterTopics"
                @focus="visibleSelectDropdown"
              >
              <div class="add-new-topic-select__dropdown" v-if="selectDropdown && filteredTopics.length > 0 && audienceChoice === 'public'">
                <p v-for="topic in filteredTopics" :key="topic.value" @click="selectTopic(topic.label)">
                  {{ topic.label }}
                </p>
              </div>
            </div>
            <a-select ref="select" v-model:value="audienceChoice" class="public-private-select" >
              <a-select-option value="public"  @click.stop
                ><div class="select-item add-new-topic-public-private-dropdown">
                  <img
                    src="@/assets/icons/public-icon.svg"
                    style="width: 1.6rem; margin-right: 0.9rem;"
                    class="image"
                  />Public
                </div></a-select-option
              >
              <a-select-option value="private" @click.stop
                ><div class="select-item add-new-topic-public-private-dropdown">
                  <img
                    src="@/assets/icons/private-icon.svg"
                    style="width: 1.6rem; margin-right: 0.9rem;"
                    class="image"
                  />Private
                </div></a-select-option
              >
            </a-select>
          </div>
        </div>
        <p class="error-message" v-if="topicFieldError">
          {{ topicFieldError }}
        </p>
        <div class="input-field">
          <p class="title">Invite Others to Discuss This Topic</p>
          <div class="input-field">
            <TagifyContacts
              placeholder="Invite via Glocal"
              :options="options"
              @fetchSelection="fetchedSelection"
              :isSubmitted="isSubmit"
              :removeFields="emptyTagifyInput"
              id="2"
            ></TagifyContacts>
          </div>
          <div class="input-field">
            <tagify-email
              placeholder="Invite via email"
              @fetchEmail="fetchedEmail"
              :isSubmitted="isSubmit"
              :nullify="emptyTagifyInput"
            ></tagify-email>
          </div>
        </div>
        <div class="input-field">
          <p class="title">Select Specific Sources</p>
          <specific-sources :emptySources="emptySelectedSourcesInput">
          </specific-sources>
          <a-button
            :loading="createTopicBtnLoader"
            @click="createNewTopic"
            class="btn"
          >
            Submit
          </a-button>
        </div>
        <div class="line"></div>
        <div class="input-field">
          <p class="title">Request Sources</p>
          <request-news-source
            :requestType="'news source'"
          ></request-news-source>
        </div>
      </div>
    </div>
  </div>
  <!-- Success or Failure message toaster -->
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
</template>

<script>
import RequestNewsSource from "./RequestNewsSource.vue";
import SpecificSources from "./sourcesFilters/SpecificSources.vue";
import TagifyContacts from "../PdbComponents/TagifyContacts.vue";
import TagifyEmail from "../PdbComponents/TagifyEmail.vue";
import MessageToaster from "../BaseComponents/MessageToaster.vue";

export default {
  components: {
    RequestNewsSource,
    TagifyContacts,
    SpecificSources,
    TagifyEmail,
    MessageToaster,
  },
  data() {
    return {
      audienceChoice: "public",
      activeTab: "1",
      activeKey2: "1",
      addTopic: "",
      topicFieldError: "",
      oldTopics: [],
      createTopicBtnLoader: false,
      addTopicBtnLoader: false,
      options: [],
      isSubmit: false,
      emptyTagifyInput: false,
      selectedUsers: [],
      emails: [],
      selectedTopics: [],
      removeFields: false,
      emptySelectedSourcesInput: false,
      showMessageModal: false,
      message: {},
      removingTopic: [],
      selectDropdown: false,
      filteredTopics: []
    };
  },
  props: {
    visible: Boolean,
    updateTopicList: Boolean,
    drawerVisible: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    updateTopicList: {
      handler(val) {
        if (val) {
          this.$store.commit("discover/SET_SPECIFIC_NEWS_SOURCES", "");
          this.emptyTagifyInput = true;
          this.fetchContacts();
          this.emptyTagifyInput = false;
        } else {
          this.$store.dispatch("discover/getAllSources");
        }
        this.topicList.forEach((val) => {
          this.selectedTopics.push(val.interest.id);
        });
      },
      immediate: true,
    },
    addTopic() {
      this.fetchAndFilterTopics();
    },
    drawerVisible(newVal) {
      if (newVal) {
        this.emptyFields();
      }
    },
  },
  computed: {
    suggestionTopics() {
      return this.$store.getters["discover/getSuggestionInterests"];
    },
    getSelectedSpecificSources() {
      const sources = this.$store.getters["discover/getSelectedSpecificSources"]
        .filter((val) => val !== "")
        .map((val) => val.key);
      return sources;
    },
    topicList: {
      get() {
        return this.$store.getters["profile/topicsCategories"];
      },
      set(val) {
        this.$store.commit("profile/setUsersCategories", val);
      },
    },
    oldSelectedTopics() {
      return this.$store.getters["discover/getOldSelectedTopics"];
    },
  },
  methods: {
    emptyFields() {
      this.addTopic = "";
      this.emails = [];
      this.selectedUsers = [];
      this.topicFieldError = "";
      this.audienceChoice = 'public'
    },
    equalArray(a, b) {
      if (a.length === b.length) {
        for (var i = 0; i < a.length; i++) {
          if (!a.includes(b[i])) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    async createNewTopic() {
      if (this.addTopic === "") {
        this.topicFieldError = "This field can not be empty.";
        return false;
      }
      let value = this.audienceChoice === "private" ? true : false;
      this.selectedTopics = [];
      // removing duplicates
      this.selectedUsers = [...new Set(this.selectedUsers)];
      try {
        this.createTopicBtnLoader = true;
        const payload = [
          {
            interest: this.addTopic,
            is_private: value,
            sources: this.getSelectedSpecificSources,
            connection_list: this.selectedUsers,
            emails: this.emails,
            permission: "admin"
          },
        ];
        const response = await this.$store.dispatch(
          "profile/craeteNewInterest",
          payload
        );
        if (response.status == 200) {
          this.emitter.emit("topicAddedSuccMsg");
          this.emitter.emit("close-create-topic-drawer");
          this.emptyFields();
          this.emptySelectedSourcesInput = true;
          this.emptyTagifyInput = true;
          //updating tagify array for displaying topics
          this.selectedTopics = [...this.selectedTopics, response.data[0].id];
          this.topicList.forEach((val) => {
            this.oldTopics.push(val.id);
          });
          // removing duplicates and empty strings
          this.oldTopics = [
            ...new Set(
              this.oldTopics.filter((filterTopic) => filterTopic !== "")
            ),
          ];
          await this.$store.dispatch("profile/getUserInterestList");
          this.isSubmit = true;
          this.isSubmit = false;
          await this.$store.dispatch("profile/getUserTopicsWithOrdering");
          this.emitter.emit("close-create-topic-drawer");
        }
        this.createTopicBtnLoader = false;
        this.emptySelectedSourcesInput = false;
        this.emptyTagifyInput = false;
      } catch (err) {
        this.topicFieldError = err.response.data[0];
        this.createTopicBtnLoader = false;
      }
    },
    fetchedSelection(val) {
      val.map((contacts) => {
        this.selectedUsers.push(contacts.value);
      });
    },
    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer);
      }
      this.$_debounceTimer = setTimeout(() => {
        method();
      }, timer);
    },
    async fetchContacts() {
      try {
        this.options = [];
        const payload = {
          user_id: this.$store.getters["auth/getUserId"],
        };
        const response = await this.$store.dispatch(
          "profile/getFriendsList",
          payload
        );
        if (response.status == 200) {
          this.options = response.data.results.map((val) => {
            if (val.profile) {
              let userList = {
                label: val.profile.full_name,
                value: val.id,
                key: val.email,
                icon: val.profile.profile_picture,
              };
              return userList;
            }
          });
        }
        // this.debounce(async () => {
        //   const payload = {
        //     user_id: this.$store.getters["auth/getUserId"],
        //   };
        //   const response = await this.$store.dispatch(
        //     "profile/getFriendsList",
        //     payload
        //   );
        //   if (response.status == 200) {
        //     this.options = response.data.friends.map((val) => {
        //       if (val.profile) {
        //         let userList = {
        //           label: val.profile.full_name,
        //           value: val.id,
        //           key: val.email,
        //           icon: val.profile.profile_picture,
        //         };
        //         return userList;
        //       }
        //     });
        //   }
        // }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    fetchedEmail(val) {
      this.emails = val;
    },
    async fetchAndFilterTopics() {
      if (this.addTopic.trim() === '') {
        this.filteredTopics = [];
        this.selectDropdown = false;
        return;
      }
      try {
        this.selectDropdown = true
        // Dispatch action to fetch suggestions
        await this.$store.dispatch('discover/interestSuggestions', { name: this.addTopic });
        // Filter the suggestions based on the input
        this.filteredTopics = this.suggestionTopics.filter(topic =>
          topic.label.toLowerCase().includes(this.addTopic.toLowerCase())
        );
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    selectTopic(selectedValue) {
      this.addTopic = selectedValue;
      this.$store.dispatch('discover/setSelectedTopic', selectedValue);
      this.selectDropdown = false;
    },
    visibleSelectDropdown() {
      setTimeout(() => {
        this.selectDropdown = false;
      }, 200);
    },
  },

};
</script>

<style lang="scss">
.add-new-topic-container {
  background-color: $color-white;
  height: 100vh;
  width: 100%;

  .add-new-topic {
    background-color: transparent;
    &__body {
      padding-right: 0.5rem;
      .input-field {
        margin-bottom: 2.5rem;
        .title {
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          font-weight: 500;
          margin-bottom: 1.2rem;
          line-height: 1.6rem;
          width: fit-content;
          color: $color-black;
        }
        input {
          border-radius: 5px !important;
          font-size: 1.4rem;
          border: 1px solid #c5c6cc;
          font-family: $font-primary;
          width: 100%;
          height: 4rem;
          padding: 1rem;
          margin-bottom: 1.5rem;
          outline: none;
          &::placeholder {
            opacity: 0.4;
          }
        }
        .tagify {
          font-size: 1.4rem;
          font-family: $font-primary;
          &__input {
            padding: 1rem 1rem;
            font-size: 1.4rem;
            &::before {
              max-width: 87%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &__tag {
            display: flex;
            align-items: center;
            margin: 0.4rem 0 0.2rem 0.5rem;
            background-color: #e5e5e5;
            border-radius: 0.5rem;
            padding-right: 0.5rem;
            &:hover,
            &:focus {
              box-shadow: none;
              background-color: #cfd7ef;
            }
            .tagify__tag__user {
              .tagify__tag__avatar-wrap {
                width: 2.6rem;
                height: 2.4rem;
              }
              .tagify__tag-text {
                font-size: 1.4rem;
                line-height: 2rem;
              }
              &::before {
                background-color: transparent;
              }
            }
          }
        }
        .add-input {
          display: flex;
          border: 1px solid rgba(0, 0, 0, 0.2);
          align-items: center;
          background-color: $color-white;
          height: 4rem;
          border-radius: 0.5rem;
          margin-bottom: 1.5rem;
          &__input {
            border: none;
            margin: 0;
            height: 100%;
            padding: 0 0.8rem;
            width: 100%;
            outline: none;
            font-size: 1.4rem;
            font-family: $font-primary;
            border-start-end-radius: 0 !important;
            border-end-end-radius: 0 !important;
            border-right: 1px solid rgba(0, 0, 0, 0.2);
            margin: 0 1.4rem 0 0.2rem;
            &::placeholder {
              opacity: 0.4;
              color: $color-black;
            }
          }
          .add-new-topic-select {
            width: 100%;
            position: relative;
            input {
              margin-bottom: 0;
              border: none;
              background-color: transparent;
              border-right: 1px solid #c5c6cc;
              border-radius: 0 !important;
              &::placeholder {
                opacity: 0.8;
              }
            }
            &__dropdown {
              position: absolute;
              width: 100%;
              background-color: $color-white;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              border-end-end-radius: 0.5rem;
              border-end-start-radius: 0.5rem;
              max-height: 12rem;
              overflow-y: auto;
              z-index: 2;
              .message{
                cursor: default;
              }
              &--loader {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem 0 !important;
                .ant-spin {
                  .anticon {
                    font-size: 2rem !important;
                  }
                }
              }
              p {
                margin-bottom: 0;
                padding: 1rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 1.6rem;
                font-family: $font-primary-medium;
                line-height: normal;
                border-bottom: 1px solid #d6d6d6;
                cursor: pointer;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1;
                -webkit-box-orient: vertical;
                &:last-child {
                  border-bottom: none;
                }
              }
            }
            &__dropdown::-webkit-scrollbar-track {
              background-color: #ffffff3f;
            }
            &__dropdown::-webkit-scrollbar {
              width: 4px;
              background-color: rgb(224, 224, 224);
              border-radius: 3rem;
            }
            &__dropdown::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 3rem;
            }
          }
          // .add-new-topic-select {
          //   height: 100%;
          //   width: 100%;
          //   .ant-select-selector {
          //     height: 100% !important;
          //     border: none;
          //     background-color: transparent;
          //     box-shadow: none;
          //     padding: 0 1rem;
          //     padding-right: 0;
          //     margin-right: 1rem;
          //     border-right: 1px solid rgba(0, 0, 0, 0.2);
          //     .ant-select-selection-search {
          //       height: 100%;
          //       left: 1rem;
          //       right: 3.5rem;
          //       .ant-select-selection-search-input {
          //         height: 100%;
          //         font-size: 1.4rem;
          //         font-family: $font-primary;
          //       }
          //     }
          //     .ant-select-selection-item {
          //       font-size: 1.4rem;
          //       font-family: $font-primary;
          //     }
          //     .ant-select-selection-placeholder {
          //       font-size: 1.4rem;
          //       opacity: 0.4;
          //       color: $color-black;
          //       font-family: $font-primary;
          //       padding-right: 3.5rem;
          //       margin-top: 0.2rem;
          //     }
          //   }
          //   .ant-select-arrow {
          //     .anticon-down {
          //       display: none;
          //     }
          //   }
          // }
          .public-private-select {
            width: fit-content;
            .ant-select-selector {
              border: none;
              box-shadow: none;
              height: 3.2rem !important;
              position: relative;
              padding: 0 2rem 0;
              .ant-select-selection-item {
                display: flex;
                align-items: center;
                padding: 0 2rem 0 2.5rem;
              }
              .select-item {
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: -2.5rem;
                background-color: $color-white;
                font-size: 1.4rem;
                font-family: $font-primary;
                line-height: normal;
              }
              &::before {
                content: "";
                position: absolute;
                background-image: url("../../assets/icons/public-icon.svg");
                height: 1.8rem;
                width: 1.8rem;
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
            .ant-select-arrow {
              display: flex;
              align-items: center;
              justify-content: center;
              right: 1.8rem;  
              .anticon {
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                  width: 1.2rem;
                  height: 1.2rem;
                  fill: $color-black;
                }
              }
            }
          }
        }
        .input-field {
          margin-bottom: 1rem;
        }
      }
      .line {
        background-color: $color-dark-grey-5;
        width: 100%;
        height: 0.7px;
        margin-bottom: 2.5rem;
      }
      .btn,
      .request-btn {
        border: none;
        outline: none;
        background-color: $color-primary;
        font-size: 1.6rem;
        color: $color-white;
        width: 14rem;
        height: 3.9rem;
        font-family: $font-primary-medium;
        border-radius: 100px !important;
        display: block;
        margin: 3rem 0 2.5rem auto;
        cursor: pointer;
        .anticon {
          width: 2rem;
          svg {
            width: 100%;
          }
        }
        span {
          margin: 0 2rem;
        }
      }
      .disbale-btn {
        opacity: 0.5;
        cursor: default;
      }
      .trending-topics-container {
        margin: 2.8rem 0 4rem;
        p {
          font-size: 1.4rem;
          font-family: $font-primary-medium;
          margin-bottom: 1.6rem;
          width: fit-content;
        }
        &__trending-topics {
          overflow-y: auto;
          max-height: 8rem;
          height: 100%;
          .message {
            font-family: $font-primary;
            text-align: center;
            width: 100%;
            margin-bottom: 0;
          }
          .topic {
            box-sizing: border-box;
            margin: 0 0.8rem 0.8rem 0.4rem;
            border-radius: 100px !important;
            color: #000000d9;
            font-variant: tabular-nums;
            list-style: none;
            font-feature-settings: tnum;
            display: inline-block;
            height: 2.8rem;
            padding: 0.5rem 1.6rem;
            white-space: nowrap;
            background-color: #d0dbff;
            border: 1px solid #3764fb;
            opacity: 1;
            transition: all 0.3s;
            position: relative;
            padding-right: 3rem;
            overflow: auto;
            p {
              margin-right: 0.5rem;
              margin-bottom: 0;
              font-size: 1.2rem;
              font-family: $font-primary;
              line-height: 1.4rem;
            }
            .icon {
              padding: 0.5rem;
              background-color: $color-white;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 0.5rem;
              font-size: 0.8rem;
              transform: rotate(45deg);
              cursor: pointer;
              width: fit-content;
              top: 50%;
              position: absolute;
              transform: translateY(-50%) rotate(45deg);
              right: 0.8rem;
              width: 1.8rem;
              height: 1.8rem;
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
        &--trending-topics::-webkit-scrollbar-track {
          background-color: #ffffff3f;
        }
        &--trending-topics::-webkit-scrollbar {
          width: 4px;
          background-color: rgb(224, 224, 224);
          border-radius: 3rem;
        }
        &--trending-topics::-webkit-scrollbar-thumb {
          background-color: $color-primary;
          border-radius: 3rem;
        }
      }
      .error-message {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: red;
        margin-bottom: 1.5rem;
        margin-top: -1.7rem;
      }
      .request-news-source-container {
        .title {
          font-size: 1.4rem;
          line-height: 2rem;
          color: $color-black;
          padding-right: 2rem;
          font-family: $font-primary;
        }
        .request-btn {
          margin-bottom: 0;
        }
      }
    }
    &__body::-webkit-scrollbar-track {
      background-color: #ffffff3f;
    }
    &__body::-webkit-scrollbar {
      width: 4px;
      background-color: rgb(224, 224, 224);
      border-radius: 3rem;
    }
    &__body::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
  }
}
</style>
