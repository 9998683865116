<template>
  <div class="read-view-sidebar">
    <div  @click="navigateToRoute()" class="read-view-sidebar__button">
      <button class="read-view-sidebar__button--btn">
        <img
          src="@/assets/icons/keyboard_arrow_down.svg"
          alt="icon"
          class="arrow-back"
        />
      </button>
      <p class="read-view-sidebar__button--title">Back</p>
    </div>
    <div class="read-view-sidebar__button">
       <the-reactions
        :articleId="articleId"
        :userReaction="userReaction"
        :isArticleReadView="isArticleReadView"
        :isMobileView="isMobileView"
      ></the-reactions> 
    </div>
    <div @click="visibleSaveArticleModal()" class="read-view-sidebar__button">
      <button class="read-view-sidebar__button--btn">
        <img src="@/assets/icons/save-icon.svg" alt="icon" />
      </button>
      <p class="read-view-sidebar__button--title">Save</p>
    </div>
    <div @click="visibleShareArticleModal()" class="read-view-sidebar__button read-view-sidebar__rate-btn">
      <button class="read-view-sidebar__button--btn">
        <img src="@/assets/icons/reply.svg" alt="icon" />
      </button>
      <p class="read-view-sidebar__button--title">Share</p>
    </div>
    <div @click="visibleShareArticleDrawer()" class="read-view-sidebar__button read-view-sidebar__mobile-rate-btn">
      <button class="read-view-sidebar__button--btn">
        <img src="@/assets/icons/reply.svg" alt="icon" />
      </button>
      <p class="read-view-sidebar__button--title">Share</p>
    </div>
    <div @click="visibleRateArticleDrawer()" class="read-view-sidebar__button read-view-sidebar__mobile-rate-btn">
      <button class="read-view-sidebar__button--btn">
        <img src="@/assets/icons/data_exploration.svg" alt="icon" />
      </button>
      <p class="read-view-sidebar__button--title">Rate</p>
    </div>
  </div>

   <!-- Share article modal -->
   <a-modal
    v-model:visible="showArticleShareModal"
    :footer="null"
    wrapClassName="share-article-modal"
  >
    <article-share-modal
      :title="title"
      :image="image"
      :source="source"
      :url="url"
      :date="date"
      :id="articleId"
      @close-article-share-modal="closeShareArticleModal"
    ></article-share-modal>
  </a-modal>

   <!-- Save article modal -->
   <a-modal
    v-model:visible="showSaveArticleModal"
    :footer="null"
    wrapClassName="save-article-modal"
  >
   <article-save-modal
    :visible="showSaveArticleModal"
    @handle-close="closeSaveArticleModal"
    :articleId="articleId"
  >
  </article-save-modal>
  </a-modal>

  <!-- Success or Failure message toaster -->
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>

  <!-- Share article drawer in mobile screen -->
  <article-share-drawer
    :dynamicClass="dynamicClass"
    :title="title"
    :image="image"
    :source="source"
    :url="url"
    :date="date"
    :id="articleId"
    :visible="showShareArticleDrawer"
    @close-share-article-drawer="closeShareArticleDrawer"
  ></article-share-drawer>

  <!-- Rate article drawer in mobile screen -->
  <a-drawer
    :title="false"
    placement="bottom"
    :closable="false"
    :visible="showRateArticleDrawer"
    class="rate-article-drawer"
  >
    <div class="rate-article-container">
      <div class="rate-article-container__header">
        <p>Rate this article</p>
        <i class="icon icon-add_plus" @click="closeRateArticleDrawer()"></i>
      </div>
      <div class="rate-article-container__body">
        <article-rating-modal
          :visible="showRateArticleDrawer"
          :articleId="articleId"
          @handle-close="closeRateArticleModal"
        >
        </article-rating-modal>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import TheReactions from '../BaseComponents/TheReactions.vue';
import ArticleShareModal from "../BaseComponents/ArticleShareModal.vue";
import ArticleSaveModal from "../BaseComponents/SaveArticleModal.vue";
import ArticleRatingModal from '../BaseComponents/ArticleRatingModal.vue';
import MessageToaster from "../BaseComponents/MessageToaster.vue";
import ArticleShareDrawer from "../BaseComponents/ArticleShareDrawer.vue";

export default{
  components: {
    TheReactions,
    ArticleShareModal,
    ArticleSaveModal,
    ArticleRatingModal,
    MessageToaster,
    ArticleShareDrawer
  },
  props: {
    articleId: String,
    title: String,
    image: String,
    source: String,
    url: String,
    date: String,
    userReaction: Object,
  },
  data(){
    return {
      showArticleShareModal: false,
      showSaveArticleModal: false,
      showDiscussionSection: false,
      message: {},
      showMessageModal: false,
      showRateArticleDrawer: false,
      showShareArticleDrawer: false,
      dynamicClass: 'activePortfolioTab',
      isMobileView: false,
      isArticleReadView: true
    }
  },
  computed: {
    activeKey(){
      return this.$store.getters['discover/getDiscussionCollapseState'];
    },
  },
  mounted(){
    this.emitter.on('close-article-share-modal', (val)=> {
      this.closeShareArticleModal(val.articleShareModal, val.showMessageModal, val.type);
    });
    if(window.innerWidth < 768){
      this.isMobileView = true;
      this.isArticleReadView = false
    }
  },
  methods: {
    navigateToRoute() {
      this.$router.back();
    },
    visibleShareArticleModal() {
      this.showArticleShareModal = true;
    },
    closeShareArticleModal(val, messageModal, type) {
      this.showArticleShareModal = val;
      this.showShareArticleDrawer = val;
      if (type == "success") {
        this.message = {
          title: "Article shared successfully",
          type: "success",
        };
      } else {
        this.message = {
          title: type,
          type: "failure",
        };
      }
      this.showMessageModal = messageModal;
    },
    visibleShareArticleDrawer() {
      this.showShareArticleDrawer = true
    },
    closeShareArticleDrawer() {
      this.showShareArticleDrawer = false
    },
    visibleSaveArticleModal() {
      this.showSaveArticleModal = true;
    },
    closeSaveArticleModal() {
      this.showSaveArticleModal = false;
    },
    visibleRateArticleDrawer() {
      this.showRateArticleDrawer = true
    },
    closeRateArticleDrawer() {
      this.showRateArticleDrawer = false
    }
  }
}
</script>

<style lang="scss">
.read-view-sidebar {
  padding: 3rem 0 0;
  position: sticky;
  top: 8rem;
  z-index: 222;
  background-color: $color-white;
  @include respond(laptop-large) {
    padding-top: 2rem;
  }
  &__button {
    margin-bottom: 3rem;
    width: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--btn {
      background-color: $color-dark-gray-4;
      width: 6.3rem;
      height: 6.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 100% !important;
      margin-bottom: 0.4rem;
      @include respond(laptop-large) {
        width: 5rem;
        height: 5rem;
      }
      img {
        width: 2.3rem;
        @include respond(laptop-large) {
          width: 1.7rem;
        }
      }
      .arrow-back {
        display: block;
        transform: rotate(90deg);
        margin-right: 0.5rem;
      }
    }
    &--title {
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      margin-bottom: 0;
      line-height: normal;
      @include respond(laptop-large) {
       font-size: 1.4rem;
      } 
    }
  }
  &__rate-btn {
    display: block;
    @include respond(tab-port) {
      display: none;
    }
  }
  &__mobile-rate-btn {
    display: none;
    @include respond(tab-port) {
      display: block;
    }
  }
}
.rate-article-modal {
  .ant-modal {
    width: 55rem !important;
    .ant-modal-content {
      background-color: rgb(246, 248, 255);
    }
  }
}

.rate-article-drawer {
  .ant-drawer-content-wrapper {
    height: 68% !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-body {
          height: 100%;
          padding: 3.6rem 3.2rem;
          .rate-article-container {
            height: 100%;
            &__header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 4.7rem;
              p {
                font-size: 2.8rem;
                font-family: $font-primary-bold;
                line-height: normal;
                margin-bottom: 0;
                color: $color-black;
                opacity: 1;
              }
              .icon {
                transform: rotate(45deg);
                display: block;
                cursor: pointer;
                font-size: 2.5rem;
                opacity: 1;
                color: $color-black;
              }
            }
            &__body {
              .rate-article-modal-container {
                &__header {
                  display: none;
                }
                &__body {
                  padding: 0;
                  &--input-field-container {
                    margin-bottom: 3rem;
                    &--title {
                      font-size: 2.1rem;
                      opacity: 1;
                    }
                    .ant-radio-group {
                      margin-top: 2rem;
                      margin-bottom: 0.8rem;
                      .ant-radio-wrapper {
                        padding: 0.8rem 1rem;
                        .ant-radio {
                          .ant-radio-inner {
                            width: 2rem;
                            height: 2rem;
                          }
                        }
                      }
                      span.ant-radio + * {
                        font-family: $font-primary-regular;
                        font-size: 1.6rem;
                        margin-bottom: 0px;
                      }
                    }
                    &--textarea {
                      font-size: 1.9rem;
                      height: 6.3rem;
                      padding: 1.8rem 2rem;
                      line-height: 2.5rem;
                      &::placeholder {
                        opacity: 0.7;
                      }
                    }
                  }
                  &--btn {
                    height: 7rem;
                    font-size: 2.1rem;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
