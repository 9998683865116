<template>
  <div class="recovery-email-popup">
    <div class="recovery-email-popup__logo">
      <img src="../../assets/images/Amblem2@2x.webp" />
    </div>
    <button @click="closePopup" class="recovery-email-popup__close-btn">
      <i class="icon icon-add_plus"></i>
    </button>
    <div class="recovery-email-popup__content">
      <h2>Did you know! You can always stay a part of Glocal?</h2>
      <p>
        Add a recovery email and once your batch is over you will be
        automatically switched to a personal account.
      </p>
      <button @click="openAddEmailModal()">Add Recovery Email Now</button>
    </div>
  </div>
  <recovery-email
    :visible="showAddRecoveryEmail"
    @close-modal="showAddRecoveryEmail = false"
  ></recovery-email>
</template>

<script>
import RecoveryEmail from "../Auth/AddRecoveryEmailModal.vue";

export default {
  components: {
    RecoveryEmail,
  },
  data() {
    return {
      showAddRecoveryEmail: false,
    };
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
    },
    openAddEmailModal() {
      this.showAddRecoveryEmail = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.recovery-email-popup {
  position: fixed;
  left: 4rem;
  bottom: 8rem;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  background-color: $color-white;
  padding: 2.1rem 2.8rem;
  border-radius: 10px;
  width: 38.3rem;
  &__logo {
    width: 7rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid $color-primary;
    margin-top: -5.5rem;
    background-color: $color-white;
    img {
      width: 2.7rem;
      height: auto;
    }
  }
  &__close-btn {
    position: absolute;
    right: 2.8rem;
    top: 2.1rem;
    background-color: transparent;
    line-height: normal;
    height: fit-content;
    width: fit-content;
    border: none;
    outline: none;
    cursor: pointer;
    .icon {
      font-size: 1.5rem;
      transform: rotate(45deg);
      display: block;
    }
  }
  &__content {
    margin-top: 1rem;
    h2 {
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      line-height: 2.1rem;
      margin-bottom: 0.8rem;
      max-width: 25.3rem;
      color: $color-black;
    }
    p {
      font-size: 1.2rem;
      font-family: $font-primary;
      line-height: 1.6rem;
      margin-bottom: 2.4rem;
      max-width: 27.3rem;
      color: $color-black;
    }
    button {
      background-color: $color-primary;
      font-size: 1.2rem;
      font-family: $font-primary-medium;
      height: 3.5rem;
      padding: 0 2rem;
      color: $color-white;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
