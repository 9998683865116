<template>
  <a-drawer
    :placement="drawerPlacement"
    :visible="visibleTopicSideDrawer"
    class="topic-drawer"
    :class="dynamicClass"
    width="46.5rem"
    title="Add a Topic"
    @close="closeNewTopicDrawer()"
  >
    <div class="topic-container">
      <div class="topic-container__body">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1">
            <!-- <template #tab>
              <span class="tabs-name">
                Create Topic
              </span>
            </template> -->
            <add-new-topic-bar :updateTopicList="updateTopicList" :drawerVisible="visibleTopicSideDrawer" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import AddNewTopicBar from "./AddNewTopicBar.vue";

export default {
  props: {
    visibleTopicSideDrawer: Boolean,
    updateTopicList: Boolean,
    drawerPlacement: String
  },
  components: {
    AddNewTopicBar,
  },
  watch: {
    drawerPlacement(val){
      if(val === 'bottom'){
        this.dynamicClass = 'topic-drawer-mobile-view';
      }else {
        this.dynamicClass = '';
      }
    },
  },
  data() {
    return {
      activeKey: "1",
      dynamicClass: ""
    };
  },
  mounted() {
    this.emitter.on("close-create-topic-drawer", () => {
      this.closeNewTopicDrawer();
    });
  },
  methods: {
    closeNewTopicDrawer() {
      this.$emit("close-topic-drawer", false);
    },
  },
};
</script>

<style lang="scss">
.topic-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      overflow: hidden;
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2rem 4rem;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0;
          overflow: hidden;
          .topic-container {
            position: relative;
            height: 100vh;
            &__body {
              
              .ant-tabs {
                height: 100%;
                padding: 0 2rem;
                .ant-tabs-bar {
                  border: none;
                  margin-bottom: 2.6rem;
                  display: none !important;
                  .ant-tabs-nav-container {
                    padding-bottom: 2px;
                    .ant-tabs-tab-arrow-show {
                      display: none !important;
                    }
                    .ant-tabs-nav-wrap {
                      border: none;
                      .ant-tabs-nav-scroll {
                        border: none;
                        overflow-x: auto;
                        &::-webkit-scrollbar {
                          height: 0.3rem;
                        }
                        &::-webkit-scrollbar-track {
                          background-color: $color-white;
                          border-radius: 0.8rem;
                          height: 0.3rem;
                        }

                        &::-webkit-scrollbar-thumb {
                          background-color: $color-primary;
                          border-radius: 0.8rem;
                        }
                        @include respond(phone-x-small) {
                          &::-webkit-scrollbar {
                            display: none;
                          }
                        }
                        .ant-tabs-nav {
                          border: none;
                          padding-bottom: 0;
                          .ant-tabs-tab {
                            padding: 0.6rem 2rem;
                            border-radius: 100px;
                            font-size: 1.4rem;
                            border: 1px solid $color-dark-grey-5;
                            font-family: $font-primary;
                            color: #434343;
                            margin-right: 1.2rem;
                            margin-bottom: 0.2rem;
                          }
                          .ant-tabs-tab-active {
                            font-family: $font-primary-regular;
                            background-color: $color-dark-grey-5;
                            color: $color-black;
                            opacity: 1;
                          }
                          .ant-tabs-ink-bar {
                            display: none;
                          }
                        }
                        .ant-tabs-ink-bar {
                          display: none !important;
                        }
                      }
                    }
                  }
                }
                .ant-tabs-content {
                  .ant-tabs-tabpane {
                    overflow-y: auto;
                    height: calc(100vh - 13rem);
                  }
                  .ant-tabs-tabpane::-webkit-scrollbar-track {
                    background-color: #ffffff3f;
                  }
                  .ant-tabs-tabpane::-webkit-scrollbar {
                    width: 4px;
                    background-color: rgb(224, 224, 224);
                    border-radius: 3rem;
                  }
                  .ant-tabs-tabpane::-webkit-scrollbar-thumb {
                    background-color: $color-primary;
                    border-radius: 3rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.add-new-topic-public-private-dropdown {
  @include respond(phone-x-small) {
    padding: 0;
    font-size: 2.1rem;
    line-height: 3.5rem;
    .image {
      width: 2.8rem !important;
      height: auto;
      margin-right: 1.5rem !important;
    }
  }
}
.topic-drawer-mobile-view {
  height: 77vh !important;
  .ant-drawer-content-wrapper {
    height: 100% !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          padding-bottom: 3rem;
          .ant-drawer-title {
            font-size: 3.1rem;
          }
        }
        .ant-drawer-body {
          .topic-container {
            &__body {
              .ant-tabs {
                .ant-tabs-bar {
                  .ant-tabs-nav-container {
                    .ant-tabs-tab-prev,
                    .ant-tabs-tab-next {
                      display: none;
                    }
                    .ant-tabs-nav-wrap {
                      .ant-tabs-nav-scroll {
                        .ant-tabs-nav {
                          .ant-tabs-tab {
                            font-size: 2rem;
                            padding: 0.8rem 3rem;
                          }
                        }
                      }
                    }
                  }
                }
                .ant-tabs-content {
                  .ant-tabs-tabpane {
                    height: calc(100vh - 40rem);
                    @include respond(phone-x-small) {
                      &::-webkit-scrollbar {
                        display: none;
                      }
                    }
                    .add-new-topic-container {
                      .add-new-topic {
                        &__body {
                          .input-field {
                            .title {
                              font-size: 2.1rem;
                              line-height: 3rem;
                            }
                            .tagify {
                              font-size: 2.1rem;
                              min-height: 6.2rem;
                              &__tag {
                                .tagify__tag__user {
                                  padding: 1rem;
                                  .tagify__tag-text {
                                    font-size: 2.1rem;
                                  }
                                }
                              }
                              &__input {
                                height: 100%;
                                font-size: 2.1rem;
                                padding: 1.7rem 2rem;
                              }
                            }
                            .btn {
                              width: 100%;
                              height: 6.8rem;
                              font-size: 2.1rem;
                            }
                            .add-input {
                              height: 6.2rem;
                              &__input {
                                font-size: 2.1rem;
                                line-height: 3rem;
                                padding: 0 2rem;
                              }
                              .public-private-select {
                                .ant-select-selector {
                                  .ant-select-selection-search {
                                    .ant-select-selection-search-input {
                                      font-size: 2.1rem;
                                    }
                                  }
                                  .ant-select-selection-item {
                                    padding-right: 3rem;
                                    font-size: 2.1rem;
                                    padding-left: 4rem;
                                    .select-item {
                                      font-size: 2.1rem;
                                      margin-left: -4rem;
                                    }
                                  }
                                  &::before {
                                    width: 2.8rem;
                                    height: 2.8rem;
                                    z-index: auto;
                                  }
                                }
                                .ant-select-arrow {
                                  .anticon {
                                    svg {
                                      width: 2rem;
                                      height: 2rem;
                                    }
                                  }
                                }
                              }
                              .add-new-topic-select {
                                height: 100%;
                                input {
                                  font-size: 2.1rem;
                                  height: 100%;
                                  padding: 1.7rem 2rem;
                                }
                              }
                            }
                            .request-news-source-container {
                              .title {
                                font-size: 2.1rem;
                                font-family: $font-primary-regular;
                                line-height: 3rem;
                                margin-bottom: 2.9rem;
                                color: $color-black;
                                opacity: 1;
                              }
                              .ant-form {
                                .ant-row {
                                  margin-bottom: 2rem;
                                  .ant-col {
                                    .ant-form-item-control-input {
                                      .ant-form-item-control-input-content {
                                        label {
                                          font-size: 1.9rem;
                                          color: $color-black;
                                          opacity: 1;
                                          margin-bottom: 1.9rem;
                                        }
                                        .https-field {
                                          height: 6.3rem;
                                          padding: 0 1.6rem;
                                          p, input {
                                            font-size: 2.1rem;
                                            font-family: $font-primary-regular;
                                          }
                                        }
                                        textarea {
                                          height: 10.4rem;
                                          padding: 1.6rem;
                                          font-size: 2.1rem;
                                          font-family: $font-primary-regular;
                                        }
                                        .error {
                                          font-size: 2.1rem;
                                          font-family: $font-primary-regular;
                                          line-height: 2.5rem;
                                          margin-top: 0.5rem;
                                        }
                                      }
                                    }
                                  }
                                }
                                .request-btn {
                                  width: 100%;
                                  height: 6.8rem;
                                  font-size: 2.1rem;
                                }
                              }
                            }
                          }
                          .line {
                            margin-bottom: 3rem;
                          }
                        }
                      }
                    }
                    .tab-management-container {
                      &__section {
                        &--header {
                          margin-bottom: 2.5rem;
                          .title {
                            font-size: 2.3rem;
                          }
                          .btn {
                            font-size: 1.8rem;
                            width: 17rem;
                            height: 5rem;
                            .icon {
                              font-size: 1.5rem;
                            }
                          }
                        }
                        .privacy-settings {
                          &__header {
                            &--name {
                              img {
                                width: 2.5rem;
                                margin-top: -0.5rem;
                              }
                              p {
                                font-size: 2.3rem;
                              }
                            }
                            &--dropdown {
                              font-size: 1.8rem;
                              width: 17rem;
                              height: 5rem;
                              img {
                                width: 1.5rem;
                                margin-left: 1rem;
                              }
                            }
                          }
                          &__body {
                            margin-top: 2rem;
                            p {
                              font-size: 1.8rem;
                              line-height: 2.5rem;
                            }
                          }
                        }
                        &--search-user {
                          height: 6.2rem;
                          .tagify {
                            min-height: 6.2rem;
                            padding: 0 2rem;
                            &__input {
                              font-size: 2.1rem;
                              height: 6.2rem;
                              padding: 1.8rem 0;
                            }
                          }
                          .btn {
                            font-size: 1.8rem;
                            width: 22rem;
                            height: 4.4rem;
                            .icon {
                              font-size: 1.6rem;
                            }
                          }
                        }
                        &--active-admin {
                          .header {
                            h2 {
                              font-size: 2.3rem;
                            }
                          }
                          .body {
                            .message {
                              font-size: 2.3rem;
                            }
                          }
                        }
                      }
                      &__footer {
                        .apply-btn {
                          width: 100%;
                          height: 6.8rem;
                          font-size: 2.1rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
